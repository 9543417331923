$toolbarHeight: 90px;

@media (min-width: 1280px) {

    .fab-fixed-top { 
        position: fixed;
        top: 100px !important;    
    }    

    .bgt {
        background: transparent;
        --background: transparent;
    }
    
    .header-title {
        font-size: 44px;
        font-family: $bebasBook;
        text-shadow: 2px 2px #333333;
        padding: 0;
    }
    ion-header ion-toolbar.main{
        height: $toolbarHeight;
        display: flex;
        align-items: flex-end;
        color: white;
        --background: url('../assets/images/header-back.png') no-repeat bottom left -300px; 
        padding-bottom: 7px;
        filter: drop-shadow( 0px 3px 2px $shadowColor);        

    }

    ion-toolbar.weightchips {
        height: 163px; 
        --background: url('../assets/images/weight-back.png') no-repeat bottom left; 
        filter: drop-shadow( -1px 3px 2px rgba(0, 0, 0, .4));        
    }

    ion-footer.bottom {
        display: block;
        position: fixed; 
        bottom: 0;
        left:0;
    }

    ion-content {
        --offset-bottom: -30px !important;
    }

    ion-footer ion-toolbar {
        height: 30px;
    }

    ion-menu {
        border-right: 0;
        border-left: 0;
        --background: var(--ion-color-light);
        ion-content {
            --background: transparent !important;
            --overflow: hidden;
        }
        div.logo {
            background: url('../assets/images/header-back.png') no-repeat bottom left; 
            margin-top: calc( var(--padding-top) * -1 );
            height: $toolbarHeight;
            margin-left: calc( var(--padding-start) * -1 );
            margin-right: calc( var(--padding-start) * -1 );
            padding-top: 7px;
            img {
                height: 68px;
                width: auto;                
            }
        }
        div.user {
            height: 163px;
            margin-left: calc( var(--padding-start) * -1 );
            margin-right: calc( var(--padding-start) * -1 );
            padding-left: calc( var(--padding-start) * 1 );
            padding-right: calc( var(--padding-start) * 1 );
            background-color: var(--ion-color-medium);
            margin-bottom: 5px;;
            filter: drop-shadow( 1px 3px 2px $shadowColor);        
            box-shadow: inset 0px 5px 6px -4px  $shadowColor ;
            * {
                background: transparent !important;
                --background: transparent !important;
            }
        }
        div.menu {
            background: url('../assets/images/menu-back.png') repeat-y left; 
            overflow: auto;
            height: calc(100% - 266px); 
            margin-left: calc( var(--padding-start) * -1 );
            margin-right: calc( var(--padding-start) * -1 );
            padding-left: calc( var(--padding-start) * 1 );
            padding-right: calc( var(--padding-start) * 1 );
            .station ion-label {
                text-transform: uppercase;
                font-size: x-large;                
            }
            .menu-list * {
                --background: transparent !important;
                background: transparent !important;
            }
        }
    }

    ion-app[dir=RTL] {
        ion-header ion-toolbar.main{
            --background: url('../assets/images/header-back.png') no-repeat bottom right -300px; 
        }
        ion-menu {
            div.logo {
                background: url('../assets/images/header-back.png') no-repeat bottom right; 
            }

        }
    }

    .box-shadow-inset {
        --box-shadow: inset 5px 0 9px -5px rgb(0 0 0 / 40%);        
        box-shadow: inset 5px 0 9px -5px rgb(0 0 0 / 40%);        
        min-height: 100%;
    }



    .weightchips {
        .manual {
            .native-input.sc-ion-input-md {
                padding-top: 4px;
                border: none;
            }
        }        
        font-family: Roboto;
        .main-row {
            height: 163px;
            display: flex;
            align-items: center;            
        }
        .save-buttons {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;      
            padding-top: 0;  
        }
        .manual {
            .native-input.sc-ion-input-md {
                text-shadow: 2px 2px #333333;
                padding-top: 18px !important;                
            }
        }
        ion-badge {
            width: 100%;
            padding: 12px !important;
            margin: 0 5px;
            &.small {
                font-size: 24px !important;
                font-weight: bold;
                //max-width: 275px;
                text-transform: uppercase;
                text-shadow: 2px 2px #333333;

            }
            &.large {
                font-size: 24px !important;
                font-weight: bold;
                text-transform: uppercase;
                text-shadow: 2px 2px #333333;
                --background: rgba(183,166,109, 0.5) !important;
            }
            &.status {
                font-size: 24px !important;
                font-weight: bold;
                text-transform: uppercase;
                text-shadow: 2px 2px #333333;
            }
            &.big {
                font-size: 34px !important;
                font-weight: bold;
                //max-width: 320px;
                text-transform: uppercase;
                text-shadow: 2px 2px #333333;
            }
        }
        
        .mw-460 {
            max-width: 460px;
            padding: 2px 10px;
        }
        .weights {
            display: flex;
            justify-content: space-between;
            flex-basis: 100%;       
            .truck {
                order: 0
            }
            .trolley {
                order: 2
            }
            .total {
                order: 1
            }
        }
    }    

    .menu, form.weightform {
        ion-label {
            font-size: larger !important;
        }    
    }    

}

@media (min-width: 992px) {
    .weightchips {
        .save-buttons {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;  
            .button-small {
                font-size: 20px;    
                min-width: 222px;;       
            } 
        }
    }    
}

@media (min-width: 502px) {
    .weightchips {
        .weights {
            .truck {
                order: 0
            }
            .trolley {
                order: 2
            }
            .total {
                order: 1
            }
        }
    }    

}

@media (max-width: 502px) {
    .vh-center {
        flex-basis: 100%;
    }    
}





ion-button.weightsave {
    //--background: transparent;
        font-weight: bold;
}      
