@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import "~ng-pick-datetimex/assets/style/picker.min.css";

$bebas: 'Bebas';
$bebasNeue: 'Bebas Neue';
$bebasBook: 'Bebas Neue Book';
$shadowColor: rgba(0, 0, 0, 0.4);

@mixin --background-opacity($color, $opacity) {
    --background: $color;  /*Fallback */
    --background: rgba($color, $opacity);
}

.bebas {
    font-family: $bebas;
}
.bebas-neue {
    font-family: $bebasNeue;
}
.bebas-book {
    font-family: $bebasBook;
}

.text-shadow {
    text-shadow: 3px 3px #333333;
}

.login-text {
    font-size: 23px;
    bottom: 29px;
    position: absolute;
    margin: 0 auto;
    width: 100%;
    color: white;    
    letter-spacing: 1px;
}

.ion-position-relative {
    position: relative;
}

.letter-spacing-1 {
    letter-spacing: 1px;
}

.header-title {
    font-weight: 300;
    letter-spacing: 0.075em;
    font-family: $bebas;
    text-shadow: 2px 2px #757575;

}

ion-toolbar {
    --background: var(--ion-color-primary);
    color: white;
}



.capitalize {
    text-transform: capitalize;
}

.datagrid-form-modal { 
    align-items: center;
    justify-content: center;
    --min-height: 100%; 
    --min-width: 100%; 
}
 
ion-modal {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}
 

.sc-ion-modal-md-h {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}

a.ng2-smart-action-add-add:before {
    //content: '+ ';
}

ion-label.required:after {
//    content: '*';
    color: red;
    margin-left: 5px;
}

.subreport {
    td {
        padding: 3px 5px;
    }

    thead {
        background: lightgrey;
    }

    tbody {
        tr:hover {
            background-color: #eaeaea;
        }
        tr.sum {
            font-weight: bold;
        }
    }

}

iframe.full {
    width: 100vw;
    height: calc(100vh - 35px);
}

.t-25 {
    top: -25px;
}

.label-fixed.sc-ion-label-md-h {
    -ms-flex: 0 0 150px;
    flex: 0 0 150px;
}


.ion-padding-md-top {
    @media (min-width: 768px) {
        padding-top: 2px;
    }
}

.ng2-smart-title {
    text-align: left;
    line-height: 1.3em;    
}

ng2-smart-table thead tr.ng2-smart-filters th.ng2-smart-th ng2-smart-table-filter div.ng2-smart-filter default-table-filter input-filter input::placeholder {
    color: transparent !important;
}

.ng2-smart-row {
    border-bottom: 1px dotted #717171;
}

.o-y-hidden {
    overflow-y: hidden;
}

ng2-st-tbody-edit-delete {
    display: flex;
    width: 70px;
    justify-content: space-evenly;
    align-items: center;
}

.ng2-smart-actions {
    width: 120px;
    .ng2-smart-title {
        display: none;
    }
    ng2-st-tbody-custom {
        float: left;
        display: flex;
        width: 70px;
        justify-content: space-evenly;
        align-items: center;
        margin-bottom: 7px;
    }    
    ng2-st-tbody-edit-delete {
        float: left;
    }    
}

.ng2-actions-no-add a.ng2-smart-action-add-add {
    display: none;
}

ion-footer.bottom {
    display: none;
}

.shadow-bottom {
    filter: drop-shadow( 0px 3px 2px rgba(0, 0, 0, .4));        
}

ng2-smart-table-title a {
    color: var(--ion-color-primary) !important;
}

i.far, i.fas {
    color: var(--ion-color-primary) !important;
}


form.weightform {
    ion-label {
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 0.025em;
    }    
    .extra-fields input {
       // font-weight: bold;
    }
    ion-label{
        font-size: 0.9em !important;
        }    
}

.weightchips .manual .native-input.sc-ion-input-md {
    padding-top: 6px;
    border: 1px dotted gray;
    margin: 0 10px;
}

.vh-center {
    align-items: center;
    display: flex;
    justify-content: center;        
}

.mw-80 {
    min-width: 80px;
}

ng2-smart-table-cell {
    line-height: normal;
}

input-filter input {
    border: none;
    background-color: var(--ion-color-light);
    border-radius: 4px;
}

.ng2-smart-titles th.ng2-smart-th {
    padding-bottom: 0 !important;
}

.ng2-smart-filters {
    height: 43px;
}

.custom-input {
    padding-bottom: 0;
}

ion-list.select-list {
    max-height: 300px;
    overflow-y: auto;    
}

[dir=RTL] {
    .ng2-smart-title {
        text-align: right;
    }
    ion-item.submenu {
        padding-right: 30px !important;
        padding-left: 0px !important;
    }
}

[dir=LTR] {
    ion-item.submenu {
        padding-left: 450px !important;
        padding-right: 0px !important;
    }
}

.fab-fixed-top { 
    position: fixed;
    top: 60px;    
}


 